<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Ref Inquiry Form
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form ref="formFieldsRef">

                  <input
                    v-model="form.id"
                    type="hidden"
                  >

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <b-row
                    align-v="center"
                  >

                    <b-col
                      cols="12"
                      sm="1"
                    >
                      <b-card-text>
                        I,
                      </b-card-text>
                    </b-col>

                    <b-col
                      cols="12"
                      sm="5"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Print Name"
                        rules="required"
                      >
                        <b-form-input
                          id="print-name"
                          v-model="applicant"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Print Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      sm="2"
                    >
                      <b-card-text>
                        Social Security,
                      </b-card-text>
                    </b-col>

                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Social Security Number"
                        rules="required"
                      >
                        <b-form-input
                          id="ssn"
                          v-model="socialsecuritynumber"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Social Security Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                  </b-row>

                  <b-card-text
                    class="mt-1"
                  >
                    , have applied for employment with Circle of Care, LLC. I authorized them to collect any information concerning my qualifications and performance while associated with your company. Further, I hereby release the company or the person completing this form from any and all liabilities by inputting the requested information.
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Applicant's Signature"
                        label-for="app-signature"
                      >
                        <label
                          class="sr-only"
                          for="app-signature"
                        >
                          * Applicant's Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Applicant's Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="applicantsignature"
                            v-model="form.applicantsignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.applicantsignature === '' }"
                            title="Applicant's Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.applicantsignature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignatureApp"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignatureApp"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date Signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-signed"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date-signed"
                            ref="datesigned"
                            v-model="form.datesigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <hr>
                  <h4 class="text-center">
                    APPLICANT DO NOT WRITE BELOW THIS SECTION
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Company Name"
                        label-for="company-name"
                      >
                        <label
                          class="sr-only"
                          for="company-name"
                        >
                          * Company Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Company Name"
                          rules="required"
                        >
                          <b-form-input
                            id="company-name"
                            ref="companyname"
                            v-model="form.companyname"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Company Name"
                            title="Company Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Contact Person"
                        label-for="contact-person"
                      >
                        <label
                          class="sr-only"
                          for="contact-person"
                        >
                          * Contact Person
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Contact Person"
                          rules="required"
                        >
                          <b-form-input
                            id="contact-person"
                            ref="contactperson"
                            v-model="form.contactperson"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Contact Person"
                            title="Contact Person"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Address"
                        label-for="address"
                      >
                        <label
                          class="sr-only"
                          for="address"
                        >
                          * Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                          rules="required"
                        >
                          <b-form-input
                            id="address"
                            ref="address"
                            v-model="form.address"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Address"
                            title="Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* City"
                        label-for="city"
                      >
                        <label
                          class="sr-only"
                          for="city"
                        >
                          * City
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <b-form-input
                            id="city"
                            ref="city"
                            v-model="form.city"
                            :state="errors.length > 0 ? false : null"
                            placeholder="City"
                            title="City"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* State"
                        label-for="state"
                      >
                        <label
                          class="sr-only"
                          for="state"
                        >
                          * State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          rules="required"
                        >
                          <b-form-input
                            id="state"
                            ref="state"
                            v-model="form.state"
                            :state="errors.length > 0 ? false : null"
                            placeholder="State"
                            title="State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* Zip"
                        label-for="zip"
                      >
                        <label
                          class="sr-only"
                          for="zip"
                        >
                          * Zip
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip"
                          rules="required"
                        >
                          <b-form-input
                            id="zip"
                            ref="zip"
                            v-model="form.zip"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Zip"
                            title="zip"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Phone"
                        label-for="phone"
                      >
                        <label
                          class="sr-only"
                          for="phone"
                        >
                          * Phone
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Phone"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="phone"
                              ref="phone"
                              v-model="form.phone"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="1234 567 8900"
                              title="Phone"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Fax"
                        label-for="fax"
                      >
                        <label
                          class="sr-only"
                          for="fax"
                        >
                          * Fax
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Fax"
                          rules="required"
                        >
                          <b-form-input
                            id="fax"
                            ref="fax"
                            v-model="form.fax"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Fax"
                            title="Fax"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Position/Job Title"
                        label-for="applicant-position"
                      >
                        <label
                          class="sr-only"
                          for="applicant-position"
                        >
                          * Position/Job Title
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Position/Job Title"
                          rules="required"
                        >
                          <b-form-input
                            id="applicant-position"
                            v-model="applicantposition"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Position/Job Title"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Immediate Supervisor"
                        label-for="immediate-supervisor"
                      >
                        <label
                          class="sr-only"
                          for="immediate-supervisor"
                        >
                          * Immediate Supervisor
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Immediate Supervisor"
                          rules="required"
                        >
                          <b-form-input
                            id="immediate-supervisor"
                            ref="immediatesupervisor"
                            v-model="form.immediatesupervisor"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Immediate Supervisor"
                            title="Immediate Supervisor"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-card-text>
                    1.) Date of Employment
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Employment From"
                        rules="required"
                      >
                        <b-form-group
                          label="* From"
                          label-for="emp-from"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="emp-from"
                          >
                            * From
                          </label>
                          <flat-pickr
                            id="emp-from"
                            ref="employmentfrom"
                            v-model="form.employmentfrom"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Employment From"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Employment To"
                        rules="required"
                      >
                        <b-form-group
                          label="* To"
                          label-for="emp-to"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="emp-to"
                          >
                            * To
                          </label>
                          <flat-pickr
                            id="emp-to"
                            ref="employmentto"
                            v-model="form.employmentto"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Employment To"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Reasons of Leaving"
                        label-for="reason-leaving"
                      >
                        <label
                          class="sr-only"
                          for="reason-leaving"
                        >
                          * Reasons of Leaving
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Reasons of Leaving"
                          rules="required"
                        >
                          <b-form-input
                            id="reason-leaving"
                            ref="reasonofleaving"
                            v-model="form.reasonofleaving"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Reasons of Leaving"
                            title="Reasons of Leaving"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-card-text>
                    2.) Date of Enrollment, if Student
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Enrollment From"
                        rules="required"
                      >
                        <b-form-group
                          label="* From"
                          label-for="enr-from"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="enr-from"
                          >
                            * From
                          </label>
                          <flat-pickr
                            id="enr-from"
                            ref="studentfrom"
                            v-model="form.studentfrom"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Enrollment From"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Enrollment To"
                        rules="required"
                      >
                        <b-form-group
                          label="* To"
                          label-for="enr-to"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="enr-to"
                          >
                            * To
                          </label>
                          <flat-pickr
                            id="enr-to"
                            ref="studentto"
                            v-model="form.studentto"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Enrollment To"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-card-text>
                    Evaluation
                  </b-card-text>

                  <b-row>
                    <b-col
                      v-align="center"
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Conduct"
                        label-for="conduct"
                      >
                        <label
                          class="sr-only"
                          for="conduct"
                        >
                          * Conduct
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Conduct"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="conduct"
                            v-model="form.conduct"
                            name="evaluationRadio1"
                            title="Conduct"
                          >
                            <b-form-radio
                              v-for="option in evaluationRadio"
                              :key="option.id"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="* Job dependability"
                        label-for="job-dependability"
                      >
                        <label
                          class="sr-only"
                          for="job-dependability"
                        >
                          * Job dependability
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Job dependability"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="jobdependability"
                            v-model="form.jobdependability"
                            name="evaluationRadio2"
                            title="Job dependability"
                          >
                            <b-form-radio
                              v-for="option in evaluationRadio"
                              :key="option.id"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Competency"
                        label-for="competency"
                      >
                        <label
                          class="sr-only"
                          for="competency"
                        >
                          * Competency
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Competency"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="competency"
                            v-model="form.competency"
                            name="evaluationRadio3"
                            title="Competency"
                          >
                            <b-form-radio
                              v-for="option in evaluationRadio"
                              :key="option.id"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="* Flexibility"
                        label-for="flexibility"
                      >
                        <label
                          class="sr-only"
                          for="flexibility"
                        >
                          * Flexibility
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Flexibility"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="flexibility"
                            v-model="form.flexibility"
                            name="evaluationRadio4"
                            title="Flexibility"
                          >
                            <b-form-radio
                              v-for="option in evaluationRadio"
                              :key="option.id"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                      class="rehirecontainer text-center"
                    >

                      <b-form-group
                        label="* Eligible for Rehire?"
                        label-for="rehire"
                      >
                        <label
                          class="sr-only"
                          for="rehire"
                        >
                          * Eligible for Rehire?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Rehire"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="rehire"
                            v-model="form.rehire"
                            name="rehireRadio"
                            title="Eligible for Rehire?"
                          >
                            <b-form-radio
                              v-for="option in rehireRadio"
                              :key="option.id"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      class="mt-1"
                    >

                      <b-form-group
                        label="Comments"
                        label-for="comments"
                      >
                        <label
                          class="sr-only"
                          for="comments"
                        >
                          Comments
                        </label>
                        <b-form-textarea
                          id="comments"
                          ref="comments"
                          v-model="form.comments"
                          placeholder="Comments"
                          rows="3"
                          max-rows="6"
                          title="Comments"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Signature"
                        label-for="signature"
                      >
                        <label
                          class="sr-only"
                          for="signature"
                        >
                          * Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="signature"
                            v-model="form.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.signature === '' }"
                            title="Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.signature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>
                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Position"
                        label-for="position"
                      >
                        <label
                          class="sr-only"
                          for="position"
                        >
                          * Position
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Position"
                          rules="required"
                        >
                          <b-form-input
                            id="position"
                            ref="position"
                            v-model="form.position"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Position"
                            title="Position"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date"
                            ref="date"
                            v-model="form.date"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Telephone Reference Obtained By"
                        label-for="telephone-reference-obtainedby"
                      >
                        <label
                          class="sr-only"
                          for="telephone-reference-obtainedby"
                        >
                          * Telephone Reference Obtained By
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Telephone Reference Obtained By"
                          rules="required"
                        >
                          <b-form-input
                            id="telephone-reference-obtainedby"
                            ref="telephonereferenceobtainedby"
                            v-model="form.telephonereferenceobtainedby"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Telephone reference obtained by"
                            title="Telephone Reference Obtained By"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Telephone Reference Obtained Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Telephone Reference Obtained Date"
                          label-for="telephone-reference-obtaineddate"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="telephone-reference-obtaineddate"
                          >
                            * Telephone Reference Obtained Date
                          </label>
                          <flat-pickr
                            id="telephone-reference-obtaineddate"
                            ref="telephonereferenceobtaineddate"
                            v-model="form.telephonereferenceobtaineddate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Telephone Reference Obtained Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-button
                    variant="primary"
                    type="submit"
                    class="d-none"
                    @click.prevent="validationForm"
                  >
                    Test Btn
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form8', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form10', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BCardText, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroupPrepend, BInputGroup, BFormRadioGroup, BFormRadio, BFormTextarea, VBToggle,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import { mask } from 'vue-the-mask'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,

    vSelect,
    flatPickr,
    VueSignaturePad,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const applicantsignature = ref(null)
    const signature = ref(null)

    const applicant = ref('')
    const socialsecuritynumber = ref('')
    const applicantposition = ref('')

    const input = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          applicant.value = jsonObjectLiterals.data.firstName + ' ' + jsonObjectLiterals.data.lastName
          socialsecuritynumber.value = jsonObjectLiterals.data.ssn
          applicantposition.value = jsonObjectLiterals.data.position

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm9', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                if (jsonObjectLiterals.data.applicantsignature) {
                  applicantsignature.value.fromDataURL(jsonObjectLiterals.data.applicantsignature)
                }

                if (jsonObjectLiterals.data.signature) {
                  signature.value.fromDataURL(jsonObjectLiterals.data.signature)
                }

                if (form.value.id) {
                  form.value.form = 'folder3_form9'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      applicantsignature,
      signature,

      applicant,
      socialsecuritynumber,
      applicantposition,

      input,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
      email,

      // options
      rehireRadio: [
        {
          id: '1',
          text: 'Yes',
        },
        {
          id: '2',
          text: 'No',
        },
      ],
      evaluationRadio: [
        {
          id: '1',
          text: 'Excellent',
        },
        {
          id: '2',
          text: 'Average',
        },
        {
          id: '3',
          text: 'Poor',
        },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    clearSignatureApp() {
      this.$refs.applicantsignature.clearSignature()
    },
    undoSignatureApp() {
      this.$refs.applicantsignature.undoSignature()
    },
    clearSignature() {
      this.$refs.signature.clearSignature()
    },
    undoSignature() {
      this.$refs.signature.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      let object = { isEmpty: null, data: null }

      object = this.$refs.applicantsignature.saveSignature()
      this.form.applicantsignature = !object.isEmpty ? object.data : ''

      object = this.$refs.signature.saveSignature()
      this.form.signature = !object.isEmpty ? object.data : ''
    },
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.applicantsignature) {
            self.$refs.applicantsignature.clearSignature()
            self.$refs.applicantsignature.fromDataURL(jsonObjectLiterals.data.applicantsignature)
          }

          if (jsonObjectLiterals.data.signature) {
            self.$refs.signature.clearSignature()
            self.$refs.signature.fromDataURL(jsonObjectLiterals.data.signature)
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* let object = { isEmpty: null, data: null }

      object = this.$refs.applicantsignature.saveSignature()
      this.form.applicantsignature = !object.isEmpty ? object.data : ''

      object = this.$refs.signature.saveSignature()
      this.form.signature = !object.isEmpty ? object.data : '' */

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm9' : 'app-hr/updateForm9', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
  em {
    text-decoration: underline;
  }

  .rehirecontainer {
    border: 1px solid #ccc;
    padding: 30px 15px;
  }
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
